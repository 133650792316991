<template>
  <v-window v-model="window" v-intersect="onIntersect" vertical ref="window">
    <v-window-item :key="0">
      <v-card :color="`grey lighten-2`" class="fill-height" >
        <div v-if="false">{{ lineupLock }}</div>
        <v-card-title class="pb-0" @click.stop="window=1">
          {{dualText}} {{dual.displayN}}
          <v-spacer></v-spacer>
          {{dual.startTime.format('ddd h:mm A')}}
        </v-card-title>
        <v-card-text :class="{'px-1': xs}" class="pb-0" @click.stop="window=1">
          <v-row dense>
            <v-col cols="12" class="d-flex justify-space-between">
              <dual-court-assignment
                v-if="view.adminAndRefs"
                :matches="dual.matches"
                :name="`Dual ${dual.displayN}`"
              ></dual-court-assignment>
              <div v-if="dual.courts.length > 0">Courts: {{dual.courts | formatArray}}</div>
            </v-col>
            <v-col cols="12" class="py-0 mt-n1" v-if="tournament.isNcaa && view.adminAndRefs">
              <match-print
                :tournament="tournament"
                :division="division"
                :round="round"
                :matches="matches"
                :pool="pool"
                :block="true"
                :text="true"
                :xSmall="true"
              ></match-print>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" :class="{'pr-5' : !xs}">
              <v-card shaped>
                <v-card-title class="py-1 justify-center">
                  <v-avatar
                    class="ma-1"
                    size="48"
                    tile
                  >
                    <v-img
                      v-if="homeTeam && homeTeam.logo"
                      :src="homeTeam.logo"
                      contain
                    ></v-img>
                  </v-avatar>
                </v-card-title>
                <v-card-title class="py-0 justify-center">
                  <fit-text>
                    {{homeTeam ? homeTeam.name : homeMap}}
                  </fit-text>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" :class="{'pl-5' : !xs}">
              <v-card shaped>
                <v-card-title class="py-1 justify-center">
                  <v-avatar
                    class="ma-1"
                    size="48"
                    tile
                  >
                    <v-img
                      v-if="awayTeam && awayTeam.logo"
                      :src="awayTeam.logo"
                      contain
                    ></v-img>
                  </v-avatar>
                </v-card-title>
                <v-card-title class="py-0 justify-center">
                  <fit-text>
                    {{awayTeam ? awayTeam.name : awayMap}}
                  </fit-text>
                </v-card-title>
              </v-card>
            </v-col>
            <div class="pa-0 text-center vs d-flex justify-center align-center">
              <v-avatar
                :color="'color1 color1Text--text'"
                size="48"
                class="elevation-4 title"
                style="z-index: 2"
              >
                vs
              </v-avatar>
            </div>
          </v-row>
        </v-card-text>
        <v-card-text class="py-0">
          <v-row dense>
            <v-col cols="12" class="text-center">
              <v-chip outlined :color="dual.complete ? 'black' : 'success'" small width="100%" v-if="dualStatus" class="ml-2" large>
                <v-icon small class="mr-2" v-if="!dual.complete">fas fa-signal-stream</v-icon> {{dualStatus}}
              </v-chip>
              <lineup-assign
                v-else-if="!locked"
                :teamIds="teamIds"
                :matches="matches"
                :division="division"
                :round="round"
                :name="`${round.name} ${dualText} ${dual.displayN}`"
                :lineupLock="lineupLock"
              ></lineup-assign>
            </v-col>
            <v-col cols="12" class="text-center" @click.stop="window=1">
              <v-btn color="color3" text icon>
                <v-icon>fas fa-caret-down</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-window-item>
    <v-window-item :key="2">
      <dual-card
        :dual="dual"
        :pool="pool"
        :myKey="pool.id"
        :round="round"
        :division="division"
      >
        <div class="text-center">
          <v-btn color="color3" text icon @click.stop="backToSum">
            <v-icon>fas fa-caret-up</v-icon>
          </v-btn>
        </div>
      </dual-card>
    </v-window-item>
  </v-window>
</template>

<script>
import { mapGetters } from 'vuex'
import LineupAssign from '@/components/Team/Lineups/LineupAssignDrawer.vue'
import DualCourtAssignment from './DualCourtAssignment.vue'
import DualCard from './DualCard.vue'
import moment from 'moment'
const MatchPrint = () => import('@/components/Printing/MatchPrint')

export default {
  props: ['dual', 'pool', 'myKey', 'division', 'round'],
  data () {
    return {
      window: 0,
      isIntersecting: false,
      timer: null,
      refreshKey: 1,
      timeOut: null
    }
  },
  computed: {
    ...mapGetters(['teamColors', 'tournament', 'view', 'getTeam', 'userTeams']),
    homeTeam () {
      return this.dual.homeTeam && this.tournament.activeTeams.find(f => f.id === this.dual.homeTeam.teamId)
    },
    homeMap () {
      const a = this.dual && this.dual.homeMap
      return a ? a.replace('Match', this.dualText) : null
    },
    awayTeam () {
      return this.dual.awayTeam && this.tournament.activeTeams.find(f => f.id === this.dual.awayTeam.teamId)
    },
    awayMap () {
      const a = this.dual && this.dual.awayMap
      return a ? a.replace('Match', this.dualText) : null
    },
    dualText () {
      return this.division.dualText
    },
    xs () {
      return this.$vuetify.breakpoint.xs
    },
    homeScore () {
      return this.dual.homeScore
    },
    matchesNeededForWin () {
      return this.dual.matchesNeededForWin
    },
    teamIds () {
      return this.dual.teamIds
    },
    teams () {
      return this.teamIds && this.teamIds.map(m => this.getTeam(m))
    },
    locked () {
      return this.tournament.locked || this.division.complete || this.pool.locked
    },
    matches () {
      return this.dual.matches
    },
    dualStatus () {
      return this.division.dualText.startsWith('D') && this.dual.getStatus(this.teams)
    },
    lineupLock () {
      return this.refreshKey && this.division.lineupLockObj(this.dual.startTime, this.view, this.userTeams)
    }
  },
  methods: {
    onIntersect (entries, observer) {
      this.isIntersecting = entries[0].isIntersecting
    },
    backToSum () {
      this.$vuetify.goTo(this.$refs.window, {
        duration: 300,
        offset: 50,
        easing: 'easeInOutCubic'
      })
      setTimeout(() => {
        this.window = 0
      }, 333)
    },
    checkSetRefresh () {
      if (this.lineupLock.hasLock && this.lineupLock.isLocked) return

      const d = moment(this.lineupLock.dtLock).diff(moment())
      this.timeOut = setTimeout(() => {
        this.refreshKey++
      }, d)
    }
  },
  watch: {
    isIntersecting: function (n, o) {
      if (n && this.timer) {
        console.log('cancel timer')
        clearTimeout(this.timer)
      }
      if (n === false && o === true && this.window > 0) {
        console.log('set timer')
        this.timer = setTimeout(() => {
          console.log('closing')
          this.window = 0
        }, 3000)
      }
    },
    'lineupLock.dtLock': 'checkSetRefresh'
  },
  mounted () {
    this.checkSetRefresh()
  },
  destroyed () {
    if (this.timeOut) clearTimeout(this.timeOut)
  },
  components: {
    LineupAssign,
    DualCourtAssignment,
    DualCard,
    MatchPrint
  }
}
</script>
<style scoped>
.caption {
  font-size: xx-small !important;
}
.vs {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
}

</style>
